<template>
  <vx-card
    :title="title"
    title-color="success"
  >
    <div>
      <div class="vx-row mb-12">
        <div class="vx-col sm:w-1/5 w-full">
          <label>Upload
            <div class="vx-row ml-2 mb-10">
              <small style="color: red"
                >(only: xls, xlsx)</small
              >
            </div>
          </label>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            multiple="multiple"
            accept=".xls, .xlsx"
          />
        </div>

      </div>
    
      <div class="vx-row flex justify-between m-3 mt-2">
          <div>
            <vs-button
              class="mt-2"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-plus"
              @click="handleImport()"
            >Import</vs-button
            >
            <vs-button
              class="mt-2"
              color="success"
              type="border"
              icon-pack="feather"
              icon="icon-download"
              @click="handleDownloadTemplate()"
            >Template Download</vs-button
            >
          </div>
          <div>
            <vs-button
              class="mb-2"
              icon-pack="feather"
              color="primary"
              type="border"
              icon="icon-x"
              @click="handleClose()"
              >Close</vs-button
            >
          </div>
        </div>
    </div>
  </vx-card>
</template>

<script>
import moment from "moment";
import Datepicker from "vuejs-datepicker";
export default {
  components: {
    Datepicker,
  },

  data() {
    return {
      title: "Stock Scrap - Import",
      editOrShow: false,
      code: null,
      id: this.$route.query.id ? parseInt(this.$route.query.id) : null,
      table: this.tableDefaultState(),
      warehouseID: null,
      date: moment().format("YYYY-MM-DD"),
      posting_date: moment().format("YYYY-MM-DD"),
      file_attachments: 2,
      optionItemSKU: [],
      selectItemSKU: [null],
      optionWarehouseArea: [],
      optionReason: [],
      optionWarehouse: [],
      selectedWarehouse: null,
      optionScrapType: [],
      selectedScrapType: null,
      optionSupplier: [],
      selectedSupplier: null,
      optionCostCenter: [],
      selectedCostCenter: null,
      optionChartOfAccount: [],
      selectedChartOfAccount: null,
      optionCharge: ["Internal", "Supplier"],
      selectedCharge: "Internal",
      note: null,
      itemID: [],
      unitID: [],
      warehouseAreaID: [],
      qtyArray: [],
      batchArray: [],
      expiredDateArray: [],
      reasonID: [],
      isHide: false,
      item_unit_id: 0,
      items: [{
            status: "plus",
            sku:{
                selected: null,
                options: [],
            },
            handlingUnit: {
                selected: null,
                options: [],
            },
            warehouseArea: {
                selected: null,
                options: [],
            },
            reason: {
                selected: null,
                options: [],
            },
            qty: 0,
            batch: {
                selected: null,
                options: [],
            },
            expiredDate: moment().format("YYYY-MM-DD"),
        }],
      fileAttachment: [],
      disabledDates: {
          to: new Date(Date.now() - 8640000)
      }
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
        transactionDate: null,
      };
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },

    handleClose() {
      // back to list
      this.$vs.loading();
      this.$router.push({ name: "stock-scrap" });
      this.$vs.loading.close();
    },
    getWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouses", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
            by_personal: 1
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouse = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getScrapType() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/scrap/type", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionScrapType = resp.data.records;
              if (this.optionScrapType.length > 0) {
                this.selectedScrapType = this.optionScrapType[0];
              }
            } 
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/suppliers", {
          params: {
            limit: 1000,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionSupplier = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCostCenter() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/cost-centers", {
          params: {
            length: null,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCostCenter = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getChartOfAccount() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/chart-of-accounts", {
          params: {
            length: null,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionChartOfAccount = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getReason() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/reason", {
          params: {
            limit: 1000,
            order: "reason",
            sort: "asc",
            reason_group: "Scrap"
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionReason = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getItems(val, index) {
      this.items[index].sku.selected = null;
      this.items[index].handlingUnit.selected = null;
      this.items[index].qty = 0;
      this.items[index].batch.selected = null;
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/wms/item-stock/group-items", {
          params: {
            length: 50,
            query: this.table.search,
            order: "sku_code",
            sort: "asc",
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: val.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].sku.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getItemsEdit(val, warehouse_id, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/wms/item-stock/items", {
          params: {
            length: 50,
            query: this.table.search,
            order: "sku_code",
            sort: "asc",
            warehouse_id: warehouse_id,
            warehouse_area_id: val,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].sku.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getHandlingUnit(val, index) {
      this.items[index].handlingUnit.selected = null;
      this.items[index].qty = 0;
      this.items[index].batch.selected = null;
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/wms/item-stock/group-units", {
          params: {
            length: 50,
            query: this.table.search,
            order: "unit",
            sort: "asc",
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: this.items[index].warehouseArea.selected.id,
            sku_code: val.sku_code,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].handlingUnit.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getBatch(val, index) {
      this.items[index].batch.selected = null;
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/wms/item-stock/items", {
          params: {
            length: 50,
            query: this.table.search,
            order: "id",
            sort: "desc",
            warehouse_id: this.selectedWarehouse.id,
            warehouse_area_id: this.items[index].warehouseArea.selected.id,
            sku_code: this.items[index].sku.selected.sku_code,
            unit: val.unit,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].batch.options = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getHandlingUnitEdit(item_unit_id, warehouse_area_id, warehouse_id, index) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/wms/item-stock/items", {
          params: {
            length: 50,
            query: this.table.search,
            order: "sku_code",
            sort: "asc",
            warehouse_id: warehouse_id,
            warehouse_area_id: warehouse_area_id,
            item_unit_id: item_unit_id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.items[index].handlingUnit.options = resp.data.records;
              this.items[index].batch.options = resp.data.records;
              this.items[index].batch.selected = resp.data.records[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getWarehouseArea(val) {
      var _this = this
      this.items.forEach(function(element, index){
        _this.items[index].warehouseArea.selected = null
        _this.items[index].sku.selected = null
        _this.items[index].handlingUnit.selected = null  
        _this.items[index].reason.selected = null  
        _this.items[index].qty = 0
        _this.items[index].batch.selected = null
      })
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouse-areas/warehouse/"+val.id+"/Special", {
          params: {
            length: null,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouseArea = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getWarehouseAreaEdit(val) {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/master/warehouse-areas/warehouse/"+val+"/Special", {
          params: {
            length: null,
            order: "code",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWarehouseArea = resp.data.records;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/wms/v1/scrap/" + this.id)
        .then((resp) => {
          this.$vs.loading.close();
          var _this = this
          if (resp.code == 200) {
            this.code = resp.data.scrap.Code;
            this.date = resp.data.scrap.Date;
            this.posting_date = resp.data.scrap.PostingDate;
            this.selectedCharge = resp.data.scrap.Charge;
            this.note = resp.data.scrap.Note;

            if (resp.data.scrap.WarehouseID) {
                this.setWarehouseSelected(resp.data.scrap.WarehouseID);
                this.getWarehouseAreaEdit(resp.data.scrap.WarehouseID);
            }  
            if (resp.data.scrap.ScrapTypeID) {
                  this.setScrapTypeSelected(resp.data.scrap.ScrapTypeID);
            }  
            if (resp.data.scrap.SupplierID) {
                  this.setSupplierSelected(resp.data.scrap.SupplierID);
            }  
            if (resp.data.scrap.CostCenterID) {
                  this.setCostCenterSelected(resp.data.scrap.CostCenterID);
            }  
            if (resp.data.scrap.ChartOfAccountID) {
                  this.setChartOfAccountSelected(resp.data.scrap.ChartOfAccountID);
            }  
            
            this.items = [];
            var statusAdd;
            for(var k = 0; k < resp.data.scrap_line.length; k++){
              if(k == 0){
                  statusAdd = "plus"
              }else{
                  statusAdd = "minus"
              }

              _this.items.push({
                  status: statusAdd,
                  sku:{
                      selected: null,
                      options: [],
                  },
                  handlingUnit: {
                      selected: null,
                      options: [],
                  },
                  warehouseArea: {
                      selected: null,
                      options: [],
                  },
                  reason: {
                      selected: null,
                      options: [],
                  },
                  qty: resp.data.scrap_line[k].Quantity,
                  batch: {
                      selected: null,
                      options: [],
                  },
                  expiredDate: resp.data.scrap_line[k].ItemExpiredDate,
              })

              if (resp.data.scrap_line[k].ItemID) {
                this.setSkuSelected(k, resp.data.scrap_line[k].ItemID);
              } 
              if (resp.data.scrap_line[k].UnitID) {
                this.setUnitSelected(k, resp.data.scrap_line[k].UnitID);
              } 
              if (resp.data.scrap_line[k].WarehouseAreaID) {
                 //console.log("resp.data.scrap_line[k].WarehouseAreaID", resp.data.scrap_line[k].WarehouseAreaID)
                 this.setWarehouseAreaSelected(k, resp.data.scrap_line[k].WarehouseAreaID);
                 this.getItemsEdit(resp.data.scrap_line[k].WarehouseAreaID, resp.data.scrap.WarehouseID, k);
                 this.getHandlingUnitEdit(resp.data.scrap_line[k].ItemUnitID, resp.data.scrap_line[k].WarehouseAreaID, resp.data.scrap.WarehouseID, k);
                 
              } 
              if (resp.data.scrap_line[k].ReasonID) {
                this.setReasonSelected(k, resp.data.scrap_line[k].ReasonID);
              }  

              this.fileAttachment = resp.data.scrap_attachment;

            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    setWarehouseSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/warehouse/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedWarehouse = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setScrapTypeSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/scrap/type/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedScrapType = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setSupplierSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/supplier/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedSupplier = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setCostCenterSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/cost-center/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedCostCenter = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setChartOfAccountSelected(id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/chart-of-account/" + id).then((resp) => {
        if (resp.code == 200) {
          this.selectedChartOfAccount = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setSkuSelected(index, id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/item/" + id).then((resp) => {
        if (resp.code == 200) {
          this.items[index].sku.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setUnitSelected(index, id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/unit/" + id).then((resp) => {
        if (resp.code == 200) {
          this.items[index].handlingUnit.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setWarehouseAreaSelected(index, id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/warehouse-area/" + id).then((resp) => {
        if (resp.code == 200) {
          this.items[index].warehouseArea.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    setReasonSelected(index, id) {
      this.$vs.loading();
      this.$http.get("/api/wms/v1/master/reason/" + id).then((resp) => {
        if (resp.code == 200) {
          this.items[index].reason.selected = resp.data;
          this.$vs.loading.close();
        } else {
          this.$vs.loading.close();
        }
      });
    },
    customLabelWarehouse({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelScrapType({ name, Name }) {
      
      return name ? `${name}` : `${Name}`;
    },
    customLabelSupplier({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelCostCenter({ Code, Name }) {
      return `${Code} - ${Name}`;
    },
    customLabelChartOfAccount({ Code, Name }) {
      return `${Code} - ${Name}`;
    },
    customLabelItemSKU({ sku_code, item_name, name }) {
      var skuName = item_name ? item_name : name
      return `${sku_code} - ${skuName}`;
    },
    customLabelHandlingUnit({ unit, name }){
      var huName = unit ? unit : name
      return `${huName}`;
    },
    customLabelWarehouseArea({ code, name }) {
      return `${code} - ${name}`;
    },
    customLabelBatch({ batch, expired_date }){
      var exDate = this.formatDate(expired_date);
      return `${batch} / ${exDate}`;
    },
    customLabelReason({ reason }){
      return `${reason}`;
    },
    handleChangePage(page) {
      this.table.page = page;
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 0;
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 0;
    },
    handleSearchItemSKU(searching) {
      this.table.search = searching;
      this.table.page = 0;
    },
    handleAddItem() {
      this.items.splice(this.items.length+1, 0, {
          status: "minus",
          sku:{
            selected: null,
            options: [],
          },
          handlingUnit: {
            selected: null,
            options: [],
          },
          warehouseArea: {
            selected: null,
            options: [],
          },
          reason: {
              selected: null,
              options: [],
          },
          qty: 0,
          batch:{
              selected: null,
              options: [],
          },
          expiredDate: moment().format("YYYY-MM-DD"),
      })
    },
    handleRemoveItem(index) {
      this.items.splice(index, 1)
    },
    handleAttachment() {
      if (this.$refs.file.files.length > 0) {
        this.file = this.$refs.file.files;
        for (let i = 0; i < this.$refs.file.files.length; i++) {
          console.log(this.file[i]);
          // this.uploadData(i);
          this.fileAttachment.push({
            NameFile: this.file[i].name,
            File: this.file[i],
          });
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "Please select file to import.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(file) {
      let objectURL;
      // console.log(file)
      objectURL = URL.createObjectURL(file.File);

      // link.download = file.name; // this name is used when the user downloads the file
      var href = objectURL;
      window.open(href, "_blank").focus();
    },
    handleDeleteAttachment(index) {
      this.fileAttachment = this.fileAttachment.filter((r, i) => {
        return i != index;
      });
    },
    SubmitForm() {

      this.$validator.validateAll().then((result) => {
        if (this.selectedSupplier == null) {
          this.errors.add({
            field: "Supplier",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.selectedCostCenter == null) {
          this.errors.add({
            field: "CostCenter",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.selectedChartOfAccount == null) {
          this.errors.add({
            field: "ChartOfAccount",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.selectedWarehouse == null) {
          this.errors.add({
            field: "Warehouse",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.selectedScrapType == null) {
          this.errors.add({
            field: "ScrapType",
            msg: "This field is required",
          });
          result = false;
        }
        if (this.note == null) {
          this.errors.add({
            field: "Note",
            msg: "This field is required",
          });
          result = false;
        }
        
        var _this = this
        this.items.forEach(function(element, index){
          console.log("_this.items[index].batch.selected", _this.items[index].batch.selected)
          if (_this.items[index].sku.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'SKU is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else if (_this.items[index].handlingUnit.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Handling Unit is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else if (_this.items[index].warehouseArea.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Special Area is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else  if (_this.items[index].qty == 0) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Qty is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else  if (_this.items[index].batch.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Batch is required!',
                  acceptText: 'OK',
              });
              result = false;
          }else if (_this.items[index].reason.selected == null) {
              _this.$vs.dialog({
                  type: 'alert',
                  color: 'danger',
                  title: 'Information',
                  text: 'Reason is required!',
                  acceptText: 'OK',
              });
              result = false;
          }
        })

        if (this.fileAttachment.length <= 0){
          this.errors.add({
            field: "Attachment",
            msg: "Attachment is required",
          });
          result = false;
        }
        
        if (result) {
          if (this.id) {
            this.putData();
          } else {
            this.postData();
          }         
        }
      });


    },
    postData() {
      let form = new FormData();
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("request_date", moment(this.date).format("YYYY-MM-DD"));
      form.append("posting_date", moment(this.posting_date).format("YYYY-MM-DD"));
      form.append("scrap_type_id", this.selectedScrapType.id);
      form.append("supplier_id", this.selectedSupplier.id);
      form.append("cost_center_id", this.selectedCostCenter.id);
      form.append("charge", this.selectedCharge);
      form.append("chart_of_account_id", this.selectedChartOfAccount.id);
      form.append("note", this.note);

      var _this = this
      if(this.items[0].sku.selected){
        this.items.forEach(function(element, index){
            _this.itemID[index] = _this.items[index].sku.selected.sku_code ? _this.items[index].sku.selected.sku_code : null
            _this.unitID[index] = _this.items[index].handlingUnit.selected.unit ? _this.items[index].handlingUnit.selected.unit : _this.items[index].handlingUnit.selected.name  
            _this.warehouseAreaID[index] = _this.items[index].warehouseArea.selected.ID ? _this.items[index].warehouseArea.selected.ID : _this.items[index].warehouseArea.selected.id  
            _this.reasonID[index] = _this.items[index].reason.selected.ID ? _this.items[index].reason.selected.ID : _this.items[index].reason.selected.id   
            _this.qtyArray[index] =  _this.items[index].qty
            _this.batchArray[index] =  _this.items[index].batch.selected.batch
            _this.expiredDateArray[index] =  moment(_this.items[index].batch.selected.expired_date).format("YYYY-MM-DD")

            
            form.append("item_code[]", _this.itemID[index]);
            form.append("unit[]", _this.unitID[index]);
            form.append("item_unit_id[]", _this.items[index].batch.selected.item_unit_id);
            form.append("warehouse_area_id[]", _this.warehouseAreaID[index]);
            form.append("qty[]", _this.qtyArray[index]);
            form.append("batch[]", _this.batchArray[index]);
            form.append("expired_date[]", _this.expiredDateArray[index]);
            form.append("reason_id[]", _this.reasonID[index]);
        })
      }

      for (let index = 0; index < this.fileAttachment.length; index++) {
        form.append("attachments[]", this.fileAttachment[index].File);
      }
      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/scrap/create", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Scrap has been saved",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    putData() {
      
      let form = new FormData();
      form.append("warehouse_id", this.selectedWarehouse.id);
      form.append("request_date", moment(this.date).format("YYYY-MM-DD"));
      form.append("posting_date", moment(this.posting_date).format("YYYY-MM-DD"));
      form.append("scrap_type_id", this.selectedScrapType.id);
      form.append("supplier_id", this.selectedSupplier.id);
      form.append("cost_center_id", this.selectedCostCenter.id);
      form.append("charge", this.selectedCharge);
      form.append("chart_of_account_id", this.selectedChartOfAccount.id);
      form.append("note", this.note);

      var _this = this
      if(this.items[0].sku.selected){
        this.items.forEach(function(element, index){
            _this.itemID[index] = _this.items[index].sku.selected.sku_code ? _this.items[index].sku.selected.sku_code : null 
            _this.unitID[index] = _this.items[index].handlingUnit.selected.unit ? _this.items[index].handlingUnit.selected.unit : _this.items[index].handlingUnit.selected.name
            _this.warehouseAreaID[index] = _this.items[index].warehouseArea.selected.ID ? _this.items[index].warehouseArea.selected.ID : _this.items[index].warehouseArea.selected.id  
            _this.reasonID[index] = _this.items[index].reason.selected.ID ? _this.items[index].reason.selected.ID : _this.items[index].reason.selected.id   
            _this.qtyArray[index] =  _this.items[index].qty
            _this.batchArray[index] =  _this.items[index].batch.selected.batch
            _this.expiredDateArray[index] = moment(_this.items[index].batch.selected.expired_date).format("YYYY-MM-DD")

            form.append("item_code[]", _this.itemID[index]);
            form.append("unit[]", _this.unitID[index]);
            form.append("item_unit_id[]", _this.items[index].batch.selected.item_unit_id);
            form.append("warehouse_area_id[]", _this.warehouseAreaID[index]);
            form.append("qty[]", _this.qtyArray[index]);
            form.append("batch[]", _this.batchArray[index]);
            form.append("expired_date[]", _this.expiredDateArray[index]);
            form.append("reason_id[]", _this.reasonID[index]);
        })
      }

      var attachment_id
      for (let index = 0; index < this.fileAttachment.length; index++) {
        if(typeof this.fileAttachment[index].id === "undefined"){
          attachment_id = 0
        }else{
          attachment_id = this.fileAttachment[index].id
        }
        form.append("attachment_id[]", attachment_id);
        form.append("attachments[]", this.fileAttachment[index].File);
      }
      this.$vs.loading();
      this.$http
        .put("/api/wms/v1/scrap/update/" + this.id, form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Scrap has been updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },

  mounted() {
    this.getWarehouse();
    this.getScrapType();
    this.getSupplier();
    this.getCostCenter();
    this.getChartOfAccount();
    this.getReason();
    if (this.id) {
      this.getData();
    }
  },
  watch: {
    id(v) {
      if (v) {
        this.getData();
      } else {
        Object.assign(this.$data, this.initialState());
      }
    },
    "selectedSupplier": function (val) {
      if (val) {
        this.errors.clear();
        this.selectedSupplier = val;
      } else {
        this.selectedSupplier = null;
      }
    },
    "selectedCostCenter": function (val) {
      if (val) {
        this.errors.clear();
        this.selectedCostCenter = val;
      } else {
        this.selectedCostCenter = null;
      }
    },
    "selectedChartOfAccount": function (val) {
      if (val) {
        this.errors.clear();
        this.selectedChartOfAccount = val;
      } else {
        this.selectedChartOfAccount = null;
      }
    },
  },
  computed: {
    formatDate: () => {
        return (val) => {
            const momentDate = moment.utc(val);
            return momentDate.format('DD MMM YYYY');
        };
    },
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  filters: {},
};
</script>

<style>
.vs-table--tbody-table
  .tr-values:not(.activeEdit):not(.tr-expandedx):not(.hoverFlat):hover {
  -webkit-transform: none;
  transform: none;
}
</style>
